import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`Dear Students,`}</p>
    <p><strong parentName="p"><em parentName="strong">{`[AAA---Short debrief or grading status]`}</em></strong></p>
    <p>{`Just a reminder you have only the final paper to submit by 5pm Friday.`}</p>
    <p>{`I've graded the project submissions and had a great time seeing your final work. Well done. As a quick note, many of you need to update the InVision link you included before you show your books. You should be on the main project page where you see all the thumbnails and look for the green "Share" button in the upper right. Next you see the prominent option to email the link to someone but at the bottom of that modal window is an option to get a "public share link." This gives you a nice short URL you should put into your process books. I did not take off points for this as it was a common misunderstanding and is a feature that is easy to miss.`}</p>
    <p>{`Looking forward to reading your final papers. Seniors, I wish you the best as you head out from here. If there's anything I can do for you please feel free to write me at philschanely@gmail.com. Those of you who will be here another semester or more I hope to see you in class again and hope you have a fabulous summer!`}</p>
    <p>{`Best,
`}<strong parentName="p"><em parentName="strong">{`[BBB---Prof name]`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      